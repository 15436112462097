export type ConfigType = {
  brandName: string
  logo: string
  header: {
    [productName in ProductName]?: string
  }
  description: {
    [productName in ProductName]?: string
  }
  meta: {
    title: {
      [productName in ProductName]?: string
    }
    favicon: string
    logo192: string
    logo512: string
    manifest: string
  }
}

export enum BrandName {
  audi = 'audi',
  skoda = 'skoda',
  volkswagen = 'volkswagen',
  // agentMMG = "agentMMG",
  agentNord = 'agentNord',
  authPortal = 'authPortal',
  instabank = 'instabank',
  nord = 'nord',
  unknown = 'unknown',
}

export enum BrandNameLabel {
  audi = 'Audi',
  skoda = 'Škoda',
  volkswagen = 'Volkswagen',
  nord = 'Nord',
}

export enum ProductName {
  vehicle = 'vehicle',
  travel = 'travel',
  content = 'content',
}

export enum ProductNameLabel {
  vehicle = 'Bil',
  travel = 'Reise',
  content = 'Innbo',
}

export enum FlowName {
  qb = 'qb',
  auth = 'auth',
}

export enum EnvName {
  local = 'local',
  dev = 'dev',
  acc = 'acc',
  prod = 'prod',
}

export enum ERROR_TYPES {
  general,
  missingModule,
  notFound,
}

export type BrandConfigType = {
  flow: FlowName
  product: ProductName
}

export type BrandProductFlowConfigType = {
  BRAND: BrandName
  FLOW: FlowName
  PRODUCT: ProductName
}

export type PartnerConfigType = {
  name: BrandName
  product: ProductName
  flow: FlowName
  label: BrandNameLabel
}

export type PartnerProductConfigType = {
  name: BrandName
  product: ProductName
  flow: FlowName
  label: ProductNameLabel
}

// Custom Extended Error Class
export class ExtendedError extends Error {
  status: number
  statusText: string
  response: Record<string, string>

  constructor(message: string, status: number, statusText: string, response = {}) {
    super(message)
    this.status = status
    this.statusText = statusText
    this.response = response
  }
}

export type ThemeBrandColorsType = {
  main: string
}

export type GetThemeOptionsArgType = {
  colors: ThemeBrandColorsType
}

export type BrandsMapType = Record<BrandName, BrandConfigType>
export type BrandDomainsMapType = Record<string, BrandName>
